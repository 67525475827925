.MuiSlider-root > .MuiSlider-thumb {
  width: 8px;
  height: 8px;
  margin-top: -3px;
  margin-left: -3px;
  color: #1f8cff;
}

.MuiSlider-root > .MuiSlider-thumb:last-of-type {
  margin-left: -4px;
}

.MuiSlider-root > .MuiSlider-rail {
  height: 1px;
  color: #dadae5;
  opacity: 1;
}
.MuiSlider-root > .MuiSlider-track {
  height: 1px;
  color: #1f8cff;
}

.MuiSlider-root > .MuiSlider-thumb.MuiSlider-active,
.MuiSlider-root > .MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-root > .MuiSlider-thumb:hover {
  box-shadow: none;
}

.MuiInput-root.MuiInput-underline:after {
  border: none;
}
.MuiInput-root.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #355b85;
}
